import React from 'react'
import Navbar from './Navbar'
import "../Styles/DeviceTable.css"
import "../Styles/UserCard.css"
import { getUserDetails } from "../Utility/AsyncStorageData";
import axios from "axios";
import { ToggleSlider } from "react-toggle-slider";
import Header from "./Header";
import { useNavigate } from 'react-router-dom';
import Modal from "./Modal";
import { showToast } from './Toast';

function WebDevice() {

  var userDetails = getUserDetails();
  let navigate = useNavigate();

  const [userList, setUserList] = React.useState([]);
  const [error, setError] = React.useState('');
  const [test, setTest] = React.useState(1);
  const [editBlock, setEditBlock] = React.useState(0);
  const [editUserDetail, setEditUserDetail] = React.useState({})





  const errortimer = () => {
    setError(undefined)
  }

  const toggleSwitch = async (index, value) => {
    try {


      let url = process.env.REACT_APP_BASE_URL + "/api/addUser";
      let body = {
        comp_num: userDetails.comp_num,
        user_id: value.user_id,
        is_active: value.is_active == "Y" ? "N" : "Y"

      }
      console.log("***********toggleSwitch************* ", body)
      await axios.post(url, body, {
        headers: {
          'authorization': `${userDetails.access_token}`
        },
      }).then(response => {
        // console.log("response: ", response.data);

        if (response.status === 200) {

          if (response.data.statusCode === 0) {
            setUserList(prevUserList => {
              let temp = [...prevUserList]
              temp[index].is_active = temp[index].is_active == "Y" ? "N" : "Y"
              return temp
            })

            showToast(response.data.msg, 3000, "green");
          }

          else {


            showToast(response.data.msg, 3000, "red");
            console.log(" addUser error: ", response.data.msg);
          }

          // setLoading (false);
        }
      }).catch(error => {
        console.log("error: ", error);
        if (error.response.status == 403) {
          navigate("/");
        }
        showToast(error, 3000, "red");
        return
      })
    } catch (error) {
      console.log("error ", error)
      throw error;
    }

  }

  const handleFetchUsers = async () => {
    try {

      let url = process.env.REACT_APP_BASE_URL + "/api/listOfUser";
      let body = {
        comp_num: userDetails.comp_num,
        user_id: userDetails.user_id
      }
      // console.log("--------------listOfUser------------- ", body)
      await axios.post(url, body, {
        headers: {
          'authorization': `${userDetails.access_token}`
        },
      }).then(response => {
        // console.log("response: ", response.data);

        if (response.status === 200) {

          if (response.data.statusCode === 0) {
            setUserList(response.data.list)
            // if (response.data.list.length === 0) {
            //   setShowData(true);
            // }
            // else {
            //   setShowData(false);
            // }
          }

          else {


            showToast(response.data.msg, 3000, "red");
            console.log("listOfUser error: ", response.data.msg);
          }

          // setLoading (false);
        }

        else if (response.status == 403) {
          navigate("/");
        }
      }).catch(error => {
        console.log("error: ", error);
        // if (error.response.status == 403) {
        //   navigate("/");
        // }

        showToast(error, 3000, "red");
        return
      })

    } catch (error) {
      console.log("error ", error)
      throw error;
    }

  }


  const navbarRender = (data) => {
    // console.log('Data received from child:', data);
    setTest(!test)
  };

  const editUser = (item) => {
    console.log("item", item)
    setEditUserDetail(item)
    setEditBlock(true)
  }

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setEditUserDetail(values => ({ ...values, [name]: value }))
  }

  const closeAddSensorForm = async () => {
    console.log("edituserdetails", editUserDetail)
    try {
      let url = process.env.REACT_APP_BASE_URL + "/api/addUser";
      let body = {
        comp_num: userDetails.comp_num,
        added_by: userDetails.user_id,
        user_id: editUserDetail.user_id,
        name: editUserDetail.name,
        email: editUserDetail.contact,
        role_id: editUserDetail.role_id
      }
      console.log("************addUser*********** ", body)
      await axios.post(url, body, {
        headers: {
          'authorization': `${userDetails.access_token}`
        },
      }).then(response => {
        // console.log("response: ", response.data);

        if (response.status === 200) {

          if (response.data.statusCode === 0) {
            console.log(" addUser error: ", response.data.msg);
            showToast(response.data.msg, 3000, "green");
            handleFetchUsers()
            setEditBlock(false)
            setEditUserDetail({})
            return
          }

          else {
            setEditBlock(false)
            showToast(response.data.msg, 3000, "red");
            return
          }
        }
      }).catch(error => {
        console.log("error: ", error);
        setEditBlock(false)
        showToast(error, 3000, "red");
        return
      })
      setEditBlock(false)
    } catch (error) {
      console.log("error ", error)
      throw error;
    }
  }

  const roleID =(role)=>{
    if(role == "A")
      return "Admin"
    else if(role == "C")
      return "Controller"
    else if(role == "F")
      return "Floor Manager"
    else if(role == "M")
      return "Machine Inchagre"
    else
    return "Owner"
  }

  React.useEffect(() => {
    handleFetchUsers();
  }, []);


  return (
    <div className='main'>

      <div>
        <Navbar />
      </div>

      <div className='Admin_menu'>

        <Header show={1} sendNavbarToParent={navbarRender} />

        <h3 style={{ width: "100%", textAlign: "center", color: "rgb(5, 19, 120)" }}>All Users</h3>

        {/* <div className='user'> */}
        <div className='device_table' style={{ height: '70vh',alignContent: "flex-start" }}>
          <table>
            <thead>
              <tr>
                <th style={{ borderRadius: "10px 0 0 10px" }}>Name</th>
                <th> Role</th>
                <th>Email & Contact No.</th>
                <th>Status</th>
                <th style={{ borderRadius: " 0 10px 10px 0" }}  >Action</th>
              </tr>
            </thead>
            <tbody>
              {userList.map((item, index) => (
                //   <div key={index} className='user_card'>
                //     <div className='row_1'>
                //       <div className='user_name1'>
                //         <h5>{index + 1}.</h5>
                //         <h5 style={{ marginLeft: "10px" }}>{item.name}</h5>
                //       </div>
                //       <h4>{item.role_id == 'A' ? ("Admin") : (item.role_id == 'O' ? ("Owner") : (item.role_id == 'F' ? ("Floor Manager") : ("Machine Incharge")))}</h4>
                //     </div>


                //     {(item.email !== "NA") ? (
                //       <div className='row_2'>
                //         <h5 style={{ marginLeft: "20px" }}>{item.email}</h5>

                //         {item.contact == "NA" ? (
                //           <ToggleSlider onToggle={() => { toggleSwitch(index, item) }}
                //             style={{ marginRight: "30px" }}
                //             active={item.is_active == "Y" ? (true) : (false)} />
                //         ) : (<></>)}

                //       </div>) : (<></>)}

                //     {(item.contact !== "NA") ? (
                //       <div className='row_2'>
                //         <h5 style={{ marginLeft: "20px" }}>{item.contact}</h5>

                //         <ToggleSlider onToggle={() => { toggleSwitch(index, item) }}
                //           style={{ marginRight: "30px" }}
                //           active={item.is_active == "Y" ? (true) : (false)} />
                //       </div>) : (<></>)}
                //   </div>
                // ))}

                <tr className="device_tr" key={index}>
                  <td style={{ borderRadius: "10px 0 0 10px" }}>{item.name}</td>
                  <td>{roleID(item.role_id)}</td>
                  <td>{item.email !== "NA" ? item.email : item.contact}</td>
                  <td><div onClick={() => { toggleSwitch(index, item) }} style={{
                    width: 10, height: 10, borderRadius: "50%", backgroundColor: (item.is_active === "Y" ? "green" : 'red'), textAlign: "center"
                  }}></div> </td>
                  <td style={{ borderRadius: " 0 10px 10px 0" }}><i className="fa-solid fa-pen" onClick={() => editUser(item)}></i></td>
                </tr>
              ))}
            </tbody>
          </table>


        </div>
        <Modal isOpen={editBlock} style={{ width: "60%" }}>
          <div style={{ display: "inline-flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
            <h2 style={{ margin: "0", padding: "0", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>{Object.keys(editUserDetail).length == 0 ? "Add User" : "Edit User"}</h2>
            <i className="fa fa-close" onClick={() => { setEditBlock(false); setEditUserDetail([]) }}></i>
          </div>
          <div>
            <label>Enter User Name</label>
            <input className='inputField'
              type="text"
              name="name"
              value={editUserDetail.name || ""}
              onChange={handleChange}
            />
          </div>
          {editUserDetail.user_id == undefined ?
            <div>
              <div>
                <label>Enter Contact no</label>
                <input className='inputField'
                  type="number"
                  name="contact"
                  value={editUserDetail.contact || ""}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label>Select User Role</label>
                <select
                  className="custom-dropdown"
                  name="role_id"
                  value={editUserDetail.role_id}
                  onChange={handleChange}
                >
                  <option value="" hidden>Select...</option> {/* Placeholder option */}
                  <option value="A">Admin</option>
                  <option value="O">Owner</option>
                  <option value="F">Floor Manager</option>
                  <option value="M">Machine Incharge</option>
                </select>
              </div>
            </div>
            :
            <></>
          }

          <button onClick={closeAddSensorForm}
            style={{
              backgroundColor: "#252B42 ", borderRadius: "10px", textDecoration: "none",
              border: "0", marginTop: "20px", color: "white", fontSize: "17px", fontWeight: "500", padding: 10
            }}>Submit
          </button>
        </Modal>

        {/* </div> */}
        <div className='addBlock1'>
          <i class="fa fa-plus-square" onClick={() => { setEditBlock(true) }} ></i>
        </div>

      </div>
    </div >
  )
}

export default WebDevice
