import React from 'react'
import Navbar from './Navbar'
import "../Styles/MachineLog.css"
import { getUserDetails, getSensorLog, getSensorOnTime } from "../Utility/AsyncStorageData";
import flovation from "../Assets/logo1.png"
import axios from "axios";

import moment from 'moment';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "./Modal";
import Report from '../Utility/Report'
import Header from "./Header";
import { useNavigate } from 'react-router-dom';

import { Dots } from "react-activity";
import "react-activity/dist/library.css";

function MachineLogs(props) {
    var userDetails = getUserDetails();
    var Logs = getSensorLog();
    var On_Time = getSensorOnTime();
    let navigate = useNavigate();


    const shiftValue = React.useRef(undefined);

    const date1 = React.useRef(moment().format("YYYY-MM-DD"));

    const [error, setError] = React.useState('');
    const [date, setDate] = React.useState(date1.current);
    const [shiftTime, setShiftTime] = React.useState();
    const [options, setOptions] = React.useState(
        []
    );

    const [from, setFrom] = React.useState()
    const [to, setTo] = React.useState()
    const [List, setList] = React.useState(1);
    const [hourData, setHourData] = React.useState([])
    const [machineHalts, setMachineHalt] = React.useState([])
    const [machineFreeHalts, setMachineFreeHalt] = React.useState([])
    const [open, setOpen] = React.useState(false);
    const [test, setTest] = React.useState(1);
    
    const [reasonLoading, setReasonLoading] = React.useState(false);

    const [reasonList, setReasonList] = React.useState([])
    const [reason, setReason] = React.useState({ reason: "" });

    const handleChange = (event) => {
        setShiftTime(event.target.value)
        let item = options.find(item => item.value == event.target.value);
        const [from1, to1] = item.label.split(' - ');

        setFrom(value => from1)
        setTo(value => to1)
        shiftValue.current = event.target.value
        machineHours()
    }


    const handleDateChange = (changedDate) => {
        setDate(moment(changedDate).format('YYYY-MM-DD'))
        date1.current = changedDate
        // console.log("Date changed to:", moment(changedDate).format('YYYY-MM-DD'));
        // machine_Data()
        machineHours()
    };

    const machine_Data = async () => {
        try {

            let url = process.env.REACT_APP_BASE_URL + '/shift/fetchShiftList';
            let body = {
                machine_id: Logs.machine_id,
                cDate: moment(date1.current).format("YYYY-MM-DD")
            };

            // console.log('*********************** fetchShiftList**************', body);
            await axios
                .post(url, body, {
                    headers: {
                        authorization: `${userDetails.access_token}`,
                    },
                })
                .then(response => {
                    if (response.status === 200) {

                        if (response.data.statusCode == 0) {
                            // console.log(' machine shift response', response.data);

                            const transformedArray = response.data.list.map(item => ({
                                label: item.shift_name,
                                value: String(item.shift_id)
                            }));
                            setOptions(transformedArray);

                            // const desiredShift = response.data.list.find(shift => shift.shift_id == response.data.curShift);
                            // console.log(' machine_Data desiredShift', response.data.list, desiredShift);

                            // setFrom(desiredShift.start_time)
                            // setTo(desiredShift.end_time)
                            // setShiftTime(String(desiredShift.shift_id));
                            // // setValue(String(desiredShift.shift_id));
                            // shiftValue.current = String(desiredShift.shift_id)

                            var desiredShift = 0;
                            if (response.data.curShift == 0) {
                                desiredShift = response.data.list[0].shift_id
                            }
                            else {
                                desiredShift = response.data.list.find(shift => shift.shift_id == response.data.curShift);
                            }
                            // shiftDrop({value:response.data.curShift})

                            if (desiredShift) {
                                setFrom(desiredShift.start_time)
                                setTo(desiredShift.end_time)
                                // setValue(String(desiredShift.shift_id));
                                shiftValue.current = String(desiredShift.shift_id)
                            }

                        }
                        else {
                            console.log(' fetchShiftList error', response.data.msg);
                            setError(response.data.msg);
                            setTimeout(() => errortimer(), 2000);
                            return;
                        }

                    }

                    else if (response.status === 403) {
                        navigate("/");
                    }

                })
                .catch(error => {
                    {
                        // navigate("/");
                    }
                    console.log(error)
                    setError('ERROR');
                    setTimeout(() => errortimer(), 2000)
                    return;
                });
        } catch (error) {
            console.log('error ', error);
            throw error;
        }
    };


    const machineHours = async () => {
        try {
            // console.log('*********** fetchHourList ***********');


            let url = process.env.REACT_APP_BASE_URL + '/shift/fetchHourList';
            let body = {
                machine_id: Logs.machine_id,
                shift_id: shiftValue.current ?? undefined,
                cDate: moment(date1.current).format("YYYY-MM-DD")
            };

            // console.log(' machineHours body', body);
            await axios
                .post(url, body, {
                    headers: {
                        authorization: `${userDetails.access_token}`,
                    },
                })
                .then(response => {
                    if (response.status === 200) {

                        if (response.data.statusCode === 0) {
                            // console.log("machineHours", response.data.stopList)
                            setHourData(response.data.hourList)
                            setMachineHalt(response.data.stopList)
                            setMachineFreeHalt(response.data.freeLoadList)
                        }
                        else {
                            console.log("fetchHourList error", response.data.msg)
                            setError(response.data.msg);
                            setTimeout(() => errortimer(), 2000);
                            return;
                        }

                    }
                })
                .catch(error => {
                    if (error.response.status == 403) {
                        navigate("/");
                      }
                    setError('ERROR');
                    setTimeout(() => errortimer(), 2000);
                    // return;
                });

        } catch (error) {
            console.log('error ', error);
            throw error;
        }

    };

    const updateHaltReason = async (index, halt_reason) => {
        try {

            let url = process.env.REACT_APP_BASE_URL + '/shift/updateMachineHaltReason';
            let body = {
                halt_id: index,
                halt_reason: halt_reason
            };
            await axios
                .post(url, body, {
                    headers: {
                        authorization: `${userDetails.access_token}`,
                    },
                })
                .then(response => {
                    if (response.status === 200) {

                        if (response.data.statusCode == 0) {
                            machineHours()
                            setReasonLoading(false)
                            // run_toast(response.data.msg, "success")
                            // setLoading(false)
                            return;
                        }
                        else {
                            // console.log(' fetchShiftList error', response.data.msg);
                            // run_toast(response.data.msg, "warning")
                            // setLoading(false)
                            return;
                        }
                    }

                })
                .catch(error => {
                    // console.log('fetchShiftList error ', error);
                    // run_toast(error, "warning")
                    // setLoading(false)
                    return;
                });
        } catch (error) {
            // console.log('error ', error);
            throw error;
        }
        setReasonLoading(false)

    };

    const handleReason = (index, halt_reason) => {
        const { name, value } = halt_reason.target;
        setReasonLoading(true)
        updateHaltReason(index, value);
    };

    const handleReasonList = async () => {
        try {

            let url = process.env.REACT_APP_BASE_URL + "/shift/machineHaltReason";
            let body = {
                comp_num: userDetails.comp_num,
            }
            // console.log("************handleReasonList************ ", body)
            await axios.post(url, body, {
                headers: {
                    'authorization': `${userDetails.access_token}`
                },
            }).then(response => {

                if (response.status === 200) {

                    if (response.data.statusCode === 0) {
                        console.log("************handleReasonList************ ", response.data.data)
                        setReasonList(response.data.data)
                        setError(undefined)

                    }
                    else if (response.data.statusCode === 1) {
                        setError(undefined);
                    }


                    else {

                        setError(response.data.msg)
                        setTimeout(() => errortimer(), 2000)
                        console.log(" companyWiseDeviceList error: ", response.data.msg);
                    }

                    // setLoading (false);
                }


                else if (response.status == 403) {
                    navigate("/");
                }

            }).catch(error => {
                console.log("error: ", error);
                if (error.response.status == 403) {
                    navigate("/");
                }
                // Alert.alert("ERROR")
                setError("ERROR")
                setTimeout(() => errortimer(), 2000)

                setError("Slow or no internet connected. Please check your internet connection.")
                setTimeout(() => handleReasonList(), 500)
                return
            })
        } catch (error) {
            console.log("error1 ", error)
            setError("Slow or no internet connected. Please check your internet")
            setTimeout(() => handleReasonList(), 500)
            return
        }
    }




    const errortimer = () => {
        setError(undefined)
    }

    const receiveDataFromChild = (data) => {
        // Do something with the data received from the child component
        // console.log('Data received from child:', data);
        setOpen(false);
    };

    const navbarRender = (data) => {
        // console.log('Data received from child:', data);
        setTest(!test)
    };


    React.useEffect(() => {
        machine_Data();
        machineHours();
        handleReasonList()
    }, []);

    return (
        <div className='main'>

            <div>
                <Navbar />
            </div>

            <div className='Admin_menu'>

                <Header show={1} sendNavbarToParent={navbarRender} />

                <h3 style={{ width: "100%", textAlign: "center", color: "rgb(5, 19, 120)" }}>Machine Logs</h3>

                <div className='block1'>
                    <div className='col_1'>
                        <div className='row'>
                            <div className='device_name'>
                                <h3> Machine Name: {Logs.device_name}</h3>
                                <h4> Device Name: {Logs.sensor_name}</h4>
                            </div>
                            <div className='excel'>
                                <i className="fa fa-file-excel-o" onClick={() => setOpen(true)}></i>
                            </div>
                        </div>
                        <hr style={{ border: "0.5px solid black" }}></hr>

                        <div className='machine_row2'>
                            <h5 style={{ color: "#5b5656", margin: "0px", fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif" }}>Run Time</h5>
                            <h1 style={{ color: (On_Time.mcs == 1 ? "red" : (On_Time.mcs == 2 ? "green" : "orange")), fontSize: "45px", margin: "0px", fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif" }}>{On_Time.on_time}</h1>
                            <h6 style={{ fontFamily: "Segoe UI, Tahoma, Geneva, Verdana, sans-serif", margin: "45px 0 0 0 " }} >{On_Time.efficiency}%</h6>
                        </div>


                        <hr style={{ border: "0.5px solid black", marginTop: "30px" }}></hr>

                        {/* <DatePicker selected={startDate} onChange={(date) => setStartDate(date)}
                            className="custom-datepicker" /> */}
                        <DatePicker
                            selected={date}
                            // onSelect={handleDateSelect}
                            onChange={handleDateChange}
                            className="custom-datepicker"
                            dateFormat="YYYY/MM/dd"
                            maxDate={new Date()}
                        />

                        <select
                            className="custom-dropdown"
                            name="shiftTime"
                            value={shiftTime}
                            onChange={handleChange}
                        >
                            <option value="" disabled defaultValue hidden>Select Shift Time</option>
                            {options.map((option, index) => (
                                <option key={index} value={option.value}>
                                    {option.label}
                                </option>
                            ))}

                        </select>

                        <div className='showdateBlock'>
                            <h5>{date}</h5>
                            <h5>From : {from}</h5>
                            <h5>To : {to}</h5>
                        </div>

                    </div>

                    <div className='col_1'>

                        <div className='hourData'>
                            <h2 style={{ fontSize: 18, color: '#000000', textAlign: 'center' }}>Hourly Data</h2>
                            {List === 1 ? (
                                <i className="fa fa-chevron-up" style={{ fontSize: 20, color: '#888', cursor: 'pointer' }} onClick={() => setList(0)} />
                            ) : (
                                <i className="fa fa-chevron-down" style={{ fontSize: 20, color: '#888', cursor: 'pointer' }} onClick={() => setList(1)} />
                            )}
                        </div>

                        <div style={{
                            marginTop: "2px",
                            display: List === 1 ? "flex" : 'none',
                            flexDirection: "column",
                            padding: "10px",
                            width: "95%",
                            backgroundColor: "#e1e9f2",
                            margin: "2px auto",
                            borderRadius: 10,
                            maxHeight: "200px",
                            
                            overflowY: " scroll",
                            scrollbarWidth: "none", // Firefox
                            msOverflowStyle: "none", // Internet Explorer and Edge
                            "&::WebkitScrollbar": { // WebKit (Chrome, Safari, etc.)
                                display: "none"
                            }

                        }}>{hourData.length !== 0 ?
                            <div className='hourRow'>
                                <h4 style={{ width: "40%" }}>Slot</h4>
                                <h4 style={{ width: "30%" }}>Run Time</h4>
                                <h4 style={{ width: "30%" }}>Run %</h4>
                            </div>
                            : <></>}
                            <div className='hourRow1'>
                                {hourData.length !== 0 ? hourData.map((item, index) => (
                                    <div key={index}
                                        style={{
                                            width: "100%",
                                            flexDirection: 'row',
                                            display: "inline-flex",
                                            backgroundColor: item.percentage_of_total > 60 ? (item.percentage_of_total > 80 ? "#caffbf" : "#fdffb6") : "#ffadad",
                                            justifyContent: "space-evenly",
                                            borderRadius: "5px",
                                            margin: "2px 0"
                                        }}
                                    >
                                        <h4 style={{ width: "40%" }}>{item.time_range}</h4>
                                        <h4 style={{ width: "30%" }}>{item.on_time}</h4>
                                        <h4 style={{ width: "30%" }}>{item.percentage_of_total}%</h4>
                                    </div>
                                )) : <div><h4>Hourly Data Not Present</h4> </div>}
                            </div>
                        </div>

                        <div className='hourData'>
                            <h2 style={{ fontSize: 18, color: '#000000', textAlign: 'center' }}>Machine Halts</h2>
                            {List === 2 ? (
                                <i className="fa fa-chevron-up" style={{ fontSize: 20, color: '#888', cursor: 'pointer' }} onClick={() => setList(0)} />
                            ) : (
                                <i className="fa fa-chevron-down" style={{ fontSize: 20, color: '#888', cursor: 'pointer' }} onClick={() => setList(2)} />
                            )}
                        </div>

                        <div style={{
                            marginTop: "2px",
                            display: List === 2 ? "flex" : 'none',
                            flexDirection: "column",
                            padding: "10px",
                            width: "95%",
                            backgroundColor: "#e1e9f2",
                            margin: "2px auto",
                            borderRadius: 10,
                            maxHeight: "200px",
                            
                            overflowY: " scroll",
                            scrollbarWidth: "none", // Firefox
                            msOverflowStyle: "none", // Internet Explorer and Edge
                            "&::WebkitScrollbar": { // WebKit (Chrome, Safari, etc.)
                                display: "none"
                            }
                        }}>{machineHalts.length !== 0 ?
                            <div className='hourRow'>
                                <h4 style={{ width: "10%" }}>Sno.</h4>
                                <h4 style={{ width: "20%" }}>From</h4>
                                <h4 style={{ width: "20%" }}>To</h4>
                                <h4 style={{ width: "20%" }}>Time</h4>
                                <h4 style={{ width: "30%" }}>Reason</h4>
                            </div>
                            : <></>}
                            <div className='hourRow1'>
                                {machineHalts.length !== 0 ? machineHalts.map((item, index) => (
                                    <div key={index}
                                        style={{
                                            width: "100%",
                                            flexDirection: 'row',
                                            display: "inline-flex",
                                            // backgroundColor: item.percentage_of_total > 60 ? (item.percentage_of_total > 80 ? "#caffbf" : "#fdffb6") : "#ffadad",
                                            justifyContent: "space-evenly",
                                            borderRadius: "5px",
                                            margin: "2px 0", 
                                            alignItems:"center"
                                        }}
                                    >
                                        <h4 style={{ width: "10%" }}>{index + 1}</h4>
                                        <h4 style={{ width: "20%" }}>{item.start_time.substr(11, 5)}</h4>
                                        <h4 style={{ width: "20%" }}>{item.end_time.substr(11, 5)}</h4>
                                        <h4 style={{ width: "20%" }}>{item.Duration.substr(0, 5)}</h4>
                                        { reasonLoading == true ? <Dots /> :
                                            <select style={{ width: "30%", backgroundColor:"#e1e9f2", border: 'none', color:"gray" }}
                                                className="inputField"
                                                name="reason" // Matches the key in the `reason` object
                                                value={item.halt_reason || ""} // Access the `reason` key of the state
                                                onChange={halt_item => {
                                                    handleReason(item.id, halt_item)
                                                }}
                                            >
                                                <option value="" disabled>
                                                    Select a reason
                                                </option>
                                                {reasonList.map((reasonItem) => (
                                                    <option key={reasonItem.id} value={reasonItem.reason}>
                                                        {reasonItem.reason}
                                                    </option>
                                                ))}
                                            </select>
                                        }
                                    </div>
                                )) : <div><h4>Hourly Data Not Present</h4></div>}
                            </div>
                        </div>

                        <div className='hourData'>
                            <h2 style={{ fontSize: 18, color: '#000000', textAlign: 'center' }}>Machine Free Load</h2>
                            {List === 3 ? (
                                <i className="fa fa-chevron-up" style={{ fontSize: 20, color: '#888', cursor: 'pointer' }} onClick={() => setList(0)} />
                            ) : (
                                <i className="fa fa-chevron-down" style={{ fontSize: 20, color: '#888', cursor: 'pointer' }} onClick={() => setList(3)} />
                            )}
                        </div>

                        <div style={{
                            marginTop: "2px",
                            display: List === 3 ? "flex" : 'none',
                            flexDirection: "column",
                            padding: "10px",
                            width: "95%",
                            backgroundColor: "#e1e9f2",
                            margin: "2px auto",
                            borderRadius: 10,
                            maxHeight: "200px",
                            
                            overflowY: " scroll",
                            scrollbarWidth: "none", // Firefox
                            msOverflowStyle: "none", // Internet Explorer and Edge
                            "&::WebkitScrollbar": { // WebKit (Chrome, Safari, etc.)
                                display: "none"
                            }
                        }}>{machineFreeHalts.length !== 0 ?
                            <div className='hourRow'>
                                <h4 style={{ width: "20%" }}>Sno.</h4>
                                <h4 style={{ width: "30%" }}>From</h4>
                                <h4 style={{ width: "30%" }}>To</h4>
                                <h4 style={{ width: "20%" }}>Time</h4>
                            </div> : <></>}
                            <div className='hourRow1'>

                                {machineFreeHalts.length !== 0 ? machineFreeHalts.map((item, index) => (
                                    <div key={index}
                                        style={{
                                            width: "100%",
                                            flexDirection: 'row',
                                            display: "inline-flex",
                                            // backgroundColor: item.percentage_of_total > 60 ? (item.percentage_of_total > 80 ? "#caffbf" : "#fdffb6") : "#ffadad",
                                            justifyContent: "space-evenly",
                                            borderRadius: "5px",
                                            margin: "2px 0"
                                        }}
                                    >
                                        <h4 style={{ width: "20%" }}>{index + 1}</h4>
                                        <h4 style={{ width: "30%" }}>{item.start_time.substr(11, 5)}</h4>
                                        <h4 style={{ width: "30%" }}>{item.end_time.substr(11, 5)}</h4>
                                        <h4 style={{ width: "20%" }}>{item.Duration.substr(0, 5)}</h4>
                                    </div>
                                )) :<div> <h4>Machine Free Load Not Present</h4> </div>}
                            </div>
                        </div>

                    </div>
                </div>

                <Modal isOpen={open}>
                    <div>
                        <div className='report_download'>
                            <h3>Report Download</h3>
                            <i className="fa-solid fa-xmark" onClick={() => setOpen(false)}></i>
                        </div>
                        <Report show={0} deviceData={Logs} sendDataToParent={receiveDataFromChild} />
                    </div>
                </Modal>


            </div>
        </div >
    )
}

export default MachineLogs