import React from 'react'
import Navbar from './Navbar'
import "../Styles/DeviceTable.css"
import "../Styles/UserCard.css"
import { getUserDetails } from "../Utility/AsyncStorageData";
import Header from "./Header";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import Modal from "./Modal";
import { showToast } from './Toast';

function WebHaltReason() {

  var userDetails = getUserDetails();
  let navigate = useNavigate();

  const [test, setTest] = React.useState(1);
  const [reasonList, setReasonList] = React.useState([])
  const [isAddVisible, setIsAddVisible] = React.useState(false)
  const [isDelete, setIsDelete] = React.useState(false)
  const [reason, setReason] = React.useState({})

  const navbarRender = (data) => {
    // console.log('Data received from child:', data);
    setTest(!test)
  };

  

  const handleChange = (fieldName, value) => {
    setReason((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const editReason = async (item) => {
    console.log("editReason", item)
    setIsAddVisible(true)
    setReason(item)

  }
  const handleReasonList = async () => {
    try {

      let url = process.env.REACT_APP_BASE_URL + "/shift/machineHaltReason";
      let body = {
        comp_num: userDetails.comp_num,
      }
      // console.log("************companyWiseDeviceList************ ", body)
      await axios.post(url, body, {
        headers: {
          'authorization': `${userDetails.access_token}`
        },
      }).then(response => {

        if (response.status === 200) {

          if (response.data.statusCode === 0) {
            // console.log("************companyWiseDeviceList************ ", response.data.data)
            setReasonList(response.data.data)

          }
          else if (response.data.statusCode === 1) {
            showToast(response.data.msg, 3000, "red");
          }


          else {

            showToast(response.data.msg, 3000, "red");
            console.log(" companyWiseDeviceList error: ", response.data.msg);
          }

          // setLoading (false);
        }


        else if (response.status == 403) {
          navigate("/");
        }

      }).catch(error => {
        console.log("error: ", error);
        showToast("Slow or no internet connected. Please check your internet connection.", 3000, "red");
       
        // Alert.alert("ERROR")

        return
      })
    } catch (error) {
      console.log("error1 ", error)
      showToast(error, 3000, "red");
      return
    }
  }

  const submitReason = async () => {
    // console.log("reason", reason)
    try {

      let url = process.env.REACT_APP_BASE_URL + "/shift/insertMachineHaltReason";
      let body = {
        id: reason.id,
        comp_num: userDetails.comp_num,
        reason: reason.reason,
        added_by: userDetails.user_id
      }

      console.log("reasonapi", body)
      await axios.post(url, body, {
        headers: {
          'authorization': `${userDetails.access_token}`
        },
      }).then(response => {

        if (response.status === 200) {

          if (response.data.statusCode === 0) {
            handleReasonList()
            setIsAddVisible(false)
            setReason({})
            showToast(response.data.msg, 3000, "green");
          }
          else if (response.data.statusCode === 1) {
            console.log(" submitReason error: ", response.data.msg);
            showToast(response.data.msg, 3000, "red");
          }


          else {

            showToast(response.data.msg, 3000, "red");
            console.log(" submitReason error: ", response.data.msg);
          }

          // setLoading (false);
        }


        else if (response.status == 403) {
          navigate("/");
        }

        setIsAddVisible(false)
      }).catch(error => {
        console.log("error: ", error);
       
        showToast(error, 3000, "red");
        return
      })
    } catch (error) {
      showToast(error, 3000, "red");
      return
    }

    setIsAddVisible(false)
    setReason({})
  }

  const deleteReason = async () => {
    try {

      let url = process.env.REACT_APP_BASE_URL + "/shift/insertMachineHaltReason";
      let body = {
        id: reason.id,
        comp_num: userDetails.comp_num,
        reason: reason.reason,
        added_by: userDetails.user_id,
        is_active: "N"
      }

      console.log("reasonapi", body)
      await axios.post(url, body, {
        headers: {
          'authorization': `${userDetails.access_token}`
        },
      }).then(response => {

        if (response.status === 200) {

          if (response.data.statusCode === 0) {
            handleReasonList()
            setIsDelete(false)
            setReason({})
            showToast(response.data.msg, 3000, "green");
          }
          else if (response.data.statusCode === 1) {
            console.log(" submitReason error: ", response.data.msg);
          }


          else {

            showToast(response.data.msg, 3000, "red");
            console.log(" submitReason error: ", response.data.msg);
          }

          // setLoading (false);
        }


        else if (response.status == 403) {
          navigate("/");
        }

        setIsAddVisible(false)
      }).catch(error => {
        console.log("error: ", error);
        showToast(error, 3000, "red");
        return
      })
    } catch (error) {
      console.log("error1 ", error)
      showToast(error, 3000, "red");
      return
    }

    setIsDelete(false)
    setReason({})
  }

  React.useEffect(() => {
    handleReasonList();
  }, []);

  return (
    <div className='main'>

      <div>
        <Navbar />
      </div>

      <div className='Admin_menu'>

        <Header show={1} sendNavbarToParent={navbarRender} />

        <h3 style={{ width: "100%", textAlign: "center", color: "rgb(5, 19, 120)", margin: 3 }}>Machine Halt Reason</h3>
        <div className='addBlock1' onClick={() => { setIsAddVisible(true) }}>
          <i class="fa fa-plus-square" ></i>
        </div>
        <div className='device_table'>
          <table>
            <thead>
              <tr>
                <th style={{ borderRadius: "10px 0 0 10px" }}>S.No</th>
                <th>Halt Reasons</th>
                <th style={{ borderRadius: " 0 10px 10px 0", textAlign: 'center' }} >Action</th>
              </tr>
            </thead>
            <tbody>
              {reasonList.map((item, index) => (
                <tr className="device_tr" key={index}>
                  <td style={{ borderRadius: "10px 0 0 10px" }}>{index + 1}</td>
                  <td >{item.reason}</td>
                  <td className='action' style={{ borderRadius: " 0 10px 10px 0", textAlign: 'center' }}>
                    <i className="fa-solid fa-pen" onClick={() => { editReason(item) }} ></i>
                    <i className="fa-solid fa-trash-can" onClick={() => { setReason(item); setIsDelete(true) }}></i>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>


        </div>

      </div>

      <Modal isOpen={isAddVisible}>

        <div style={{ display: "inline-flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
          <h2 style={{ margin: "0", padding: "0", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>
            {Object.keys(reason).length === 0 ? "Add Reason" : "Edit Reason"}
          </h2>
          <i className="fa fa-close" onClick={() => { setIsAddVisible(false); setReason("") }}></i>
        </div>
        <div>
          <div>
            <label>Enter Machine Halt Reason</label>
            <input className='inputField'
              type="text"
              name="reason"
              value={reason.reason || ""}
              onChange={(e) => handleChange("reason", e.target.value)}
            />
          </div>
          <button onClick={submitReason} style={{
            backgroundColor: "#252B42 ", height: "30px", borderRadius: "10px", textDecoration: "none",
            border: "0", marginTop: "30px", color: "white", fontSize: "15px", fontWeight: "500"
          }}>Submit</button>
        </div>
      </Modal>

      <Modal isOpen={isDelete}>
        <h2 style={{ margin: "0", padding: "0", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}>
          Are yoy sure you Delete {reason.reason} ?
        </h2>
        <div style={{ display: "inline-flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
          <h4 onClick={()=> {setIsDelete(false); setReason({})}}>Close</h4>
          <h4 onClick={deleteReason}>Done</h4>
        </div>

      </Modal>
    </div >
  )
}

export default WebHaltReason
