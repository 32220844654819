import React from 'react'
import Navbar from './Navbar'
import { getUserDetails, setSensorLog, setSensorOnTime, getDeviceMachines } from "../Utility/AsyncStorageData";
import axios from "axios";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Header from "./Header";

var runtimeid = 0
var statueBarInterval = 19;
var intervalId = 0;

function DeviceMachines() {


    const gb_machine_id = React.useRef("");

    let navigate = useNavigate();
    var userDetails = getUserDetails();
    var deviceWiseMachine = getDeviceMachines();

    const [error, setError] = React.useState('');
    const [graph, setGraph] = React.useState([]);
    const [runningListLog, setRunningListLog] = React.useState({});
    const [runningStatus, setRunningStatus] = React.useState({});
    const [colorWidthData, setColorWidthData] = React.useState([]);
    const [test, setTest] = React.useState(1);



    const errortimer = () => {
        setError(undefined)
    }

    const handleFetchMachineList = async () => {
        try {
            // console.log("*********** handleFetchMachineList ***********")


            let url = process.env.REACT_APP_BASE_URL + '/sensor/getSensorList';
            let body = {
                device_id: deviceWiseMachine.device_id
            }
            // console.log("===============getCompanyWiseSensorList======================", body)

            await axios.post(url, body, {
                headers: {
                    'authorization': `${userDetails.access_token}`
                },
            }).then(response => {

                if (response.status === 200) {

                    if (response.data.statusCode === 0) {
                        // console.log("getCompanyWiseSensorList======================", response.data.result)

                        const machineIds = response.data.result.map(item => item._id);
                        const machineIdsString = machineIds.map(item => `${item}`).join(', ');

                        // console.log("machineIds===================", machineIdsString);

                        const elements = machineIdsString.split(', ');
                        gb_machine_id.current = elements.map(element => `'${element}'`).join(', ');

                        //subscribeFCMTopic()

                        // console.log("response.data.resultresponse.data.resultresponse.data.result", response.data.result);

                        setGraph(response.data.result)
                        // console.log(response.data.result)

                        let temp = {};
                        let speed_temp = {};

                        Runtime()

                    }

                    else {

                        setError(response.data.msg)
                        setTimeout(() => errortimer(), 2000)
                        console.log("error in getCompanyWiseSensorList: ", response.data.msg);
                    }

                    // setLoading(false);
                }


                else if (response.status == 403) {
                    navigate("/");
                }
            }).catch(error => {
                console.log("Error", error.response.status)
                if (error.response.status == 403) {
                    navigate("/");
                }
                setError("ERROR")
                setTimeout(() => errortimer(), 2000)
                // setLoading(false);
                return
            })
        } catch (error) {
            console.log("error ", error)
            //   setLoading(false);
            throw error;
        }

    }


    const Runtime = async () => {
        try {
            // console.log('*********** getMachineStatus api ***********');

            if (gb_machine_id.current.length != 0) {


                let url = process.env.REACT_APP_BASE_URL + '/sensor/getMachineStatus';
                let body = {
                    doa: moment().format('YYYY-MM-DD'),
                    machine_id: gb_machine_id.current
                };

                // console.log('*********** api hit ***********', body);

                // body.machine_id = elements.map(element => `'${element}'`).join(', ');
                // console.log(" getMachineStatus body", body)
                await axios
                    .post(url, body, {
                        headers: {
                            authorization: `${userDetails.access_token}`,
                        },
                    })
                    .then(response => {
                        if (response.status === 200) {
                            if (response.data.statusCode === 0) {
                                // console.log("running", response.data    )
                                setRunningListLog(response.data)
                                setRunningStatus(response.data.list)

                                statueBarInterval = statueBarInterval + 1;
                                if (statueBarInterval >= 20) {

                                    // console.log("statueBarInterval", statueBarInterval)
                                    statueBarInterval = 0;
                                    dashProgressBar()
                                }

                            }
                            else if (response.data.statusCode === 2) {

                                console.log("getMachineStatus api error", response.data.msg)
                            }
                            else {

                                console.log("getMachineStatus api error", response.data.msg)
                                setError("ERROR")
                                setTimeout(() => errortimer(), 2000);
                            }
                        }
                    })
                    .catch(error => {
                        // console.log("Error", error.response.status)
                        // if (error.response.status == 403) {
                        //     navigate("/");
                        // }
                        setError('ERROR');
                        setTimeout(() => errortimer(), 2000);
                    });
                // setLoading(false);
            }
            runtimeid = setTimeout(() => Runtime(), 30000)
        } catch (error) {
            console.log('error ', error);
            runtimeid = setTimeout(() => Runtime(), 30000)
            throw error;
        }
        // setLoading(false);
    }


    const dashProgressBar = async () => {
        try {
            // console.log('*********** dashProgressBar api ***********');

            if (gb_machine_id.current.length != 0) {

                let url = process.env.REACT_APP_BASE_URL + '/shift/fetchLogWiseData';
                let a = ""
                for (let key of gb_machine_id.current) {
                    a = a + key.replace("'", "")
                }

                let body = {
                    machine_ids: a
                };

                // console.log(" dashProgressBar body", body)
                await axios
                    .post(url, body, {
                        headers: {
                            authorization: `${userDetails.access_token}`,
                        },
                    })
                    .then(response => {
                        if (response.status === 200) {
                            if (response.data.statusCode === 0) {
                                // setMachineData(response.data.list)
                                // setMachineStates(response.data)
                                setColorWidthData(response.data.result)
                                // console.log(response.data.result)
                            } else {

                                console.log("dashProgressBar api error", response.data.msg)
                                setError(response.data.msg)
                                setTimeout(() => errortimer(), 2000);
                            }
                        }
                        else if (response.status == 403) {
                            // console.log("refreshAccessToken: ");
                            // refreshAccessToken();
                            dashProgressBar();
                        }
                    })
                    .catch(error => {
                        if (error.response.status == 403) {
                            navigate("/");
                        }
                        console.log("ERROR", error)
                        setError('ERROR');
                        setTimeout(() => errortimer(), 2000);
                    });
                // setLoading(false);
            }

        } catch (error) {
            console.log('error ', error);
            setError('ERROR');
            setTimeout(() => errortimer(), 2000);
            // statueBarInterval = setTimeout(() => dashProgressBar(), 30000)
            throw error;
        }
    }

    const machineTime = (mech_id, data) => {
        if (runningStatus[mech_id]) {
            // console.log("machineTime" , runningStatus[mech_id].machine_current_status, data)
            if (runningStatus[mech_id].machine_current_status == "D") {
                return ("#E9E9E9") //gray
            }
            else {
                if (runningStatus[mech_id].efficiency >= data.Y) {
                    return "#F1F9EE" //green
                }
                else if (runningStatus[mech_id].efficiency >= data.R) {
                    return "#FEFBED" // orange
                }
                else {
                    return "#FBF3F3" // red
                }
            }
        }
        else {
            return ("#D3D3D3") //gray
        }
    }

    const machineStatus = (mech_id) => {
        // console.log(mech_id, runningStatus[mech_id],)
        if (Object.keys(runningStatus).length) {
            if (runningStatus[mech_id].machine_current_status === "D") {
                return (
                    <div style={{ color: "blue", backgroundColor: 'blue', width: "15px", height: "15px", borderRadius: "50%" }} className='clock'></div>
                )
            }
            else {
                if (runningStatus[mech_id].machine_current_state === "0") {
                    return (
                        <div style={{ color: "red", backgroundColor: 'red', width: "15px", height: "15px", borderRadius: "50%" }} className='clock'></div>
                    )
                }
                else if (runningStatus[mech_id].machine_current_state === "1") {
                    return (
                        <div style={{ color: "orange", backgroundColor: 'orange', width: "15px", height: "15px", borderRadius: "50%" }} className='clock'></div>
                    )
                }
                else {
                    return (
                        <div style={{ color: "green", backgroundColor: 'green', width: "15px", height: "15px", borderRadius: "50%" }} className='clock'></div>
                    )
                }
            }
        }


    }


    const updateOnTimeInSec = () => {
        if (Object.keys(runningStatus).length) {
            setRunningStatus((prevData) => {
                const updatedData = { ...prevData }; // Create a shallow copy of the previous state
                for (let key of graph) {
                    // console.log("key.sensor_data", updatedData, key.machine_id)
                    if (updatedData[key._id].machine_current_state === '2') {
                        const timeObject = new Date(
                            `2000-01-01T${updatedData[key._id].on_time}`
                        );
                        timeObject.setSeconds(timeObject.getSeconds() + 1);
                        const newTimeString = timeObject.toLocaleTimeString('en-US', {
                            hour12: false,
                        });

                        // Create a new object for the specific item and update the on_time property
                        updatedData[key._id] = {
                            ...updatedData[key._id],
                            on_time: newTimeString,
                        };
                    }
                }
                return updatedData;
            });
        }

    }

    const borderRadius = (serialNo, machineId) => {
        // console.log(serialNo, machineId ,colorWidthData[machineId].length )
        if (colorWidthData[machineId].length == 1) {
            return "10px"
        }
        else if (serialNo == colorWidthData[machineId].length - 1) {
            // console.log(colorWidthData[machineId])
            return " 0 10px 10px 0 "
        }
        else if (serialNo == 0) {
            return " 10px 0 0 10px"
        }
    }


    const showShift = (machine_id) => {
        const time = moment(runningStatus[machine_id].start_time, 'HH:mm:ss')
        const start = time.format('HH:mm');


        const time1 = moment(runningStatus[machine_id].end_time, 'HH:mm:ss')
        const end = time1.format('HH:mm');

        return (start + " - " + end)

    }

    const PrevshowShift = (machine_id) => {
        const time = moment(runningStatus[machine_id].previousData.shift_start_time)
        const start = time.format('HH:mm');


        const time1 = moment(runningStatus[machine_id].previousData.shift_end_time)
        const end = time1.format('HH:mm');

        // console.log("start time - end time", start,end, time, time1)
        return (start + " - " + end)

    }

    clearInterval(intervalId);
    intervalId = setInterval(() => {
        updateOnTimeInSec();
    }, 1000);

    const navbarRender = (data) => {
        // console.log('Data received from child:', data);
        setTest(!test)
    };

    React.useEffect(() => {
        handleFetchMachineList()
    }, []);
    return (
        <div className='main'>

            <div>
                <Navbar />
            </div>

            <div className='Admin_menu'>
                <Header show={1} sendNavbarToParent={navbarRender} />


                <div className='machines'>
                    {/* 
                    {graph.length ?
                        (graph.map((item, index) => (
                            <div key={index} className='machine_card'>
                                <div className='row1'>
                                    <div style={{ display: "flex", width: "100%", alignItems: "center" }}>
                                        <h4 style={{ textAlign: "center", width: "80%" }}>{item.sensor_name}</h4>

                                        {runningStatus.hasOwnProperty(item._id) ? machineStatus(item._id)
                                            : <h6 style={{
                                                backgroundColor: "white",
                                                width: 30,
                                                borderRadius: 50,
                                            }}> </h6>
                                        }
                                    </div>

                                    <div style={{ marginTop: "5px", display: "flex", width: "100%" }}>
                                        <h6 style={{ width: "30%" }}>{deviceWiseMachine.device_name}</h6>
                                        <div style={{ display: "flex", width: "70%" }}>
                                            <h6 style={{ textAlign: "right", width: "100%" }}>Shift : {((Object.keys(runningStatus).length) != 0 && (runningStatus[item._id])) ? (runningStatus[item._id].start_time + " - " + runningStatus[item._id].end_time) : ("NA")}</h6>
                                        </div>
                                    </div>
                                </div>

                                <hr className='line'></hr>

                                <div className='row2'>
                                    <h5 style={{ color: "#5b5656" }}>Run Time</h5>
                                    {((Object.keys(runningStatus).length) != 0 && (runningStatus[item._id])) ? (machineTime(item._id)) : ("--:--:--")}

                                    <h6 style={{ marginTop: "45px" }}>{((Object.keys(runningStatus).length) != 0 && (runningStatus[item._id])) ? (runningStatus[item._id].efficiency) : ("0")}%</h6>
                                </div>

                                <div style={{ display: 'flex', width: '100%', height: '10px', borderRadius: '10px', marginBottom: "3px" }}>
                                    {(Object.keys(colorWidthData).length && (colorWidthData[item._id])) ?
                                        colorWidthData[item._id].map(({ color, width }, index) => (
                                            <div key={index} style={{ width, backgroundColor: color }} ></div> //(index == 0 ? '10px 0 0 10px' : (index == colorWidthData[item.sensor_data._id].length - 1 ? '0px 10px 10px 0px' : '0px')) 

                                        )) :
                                        <div key={index} style={{ width: "100%", backgroundColor: "gray", borderRadius: "10px", height: "10px" }} ></div>}
                                </div>

                                <hr className='line'></hr>

                                <div className='row3' style={{ alignItems: "center" }}>
                                    <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                        <h6 style={{ color: "#5b5656" }}>Best</h6>
                                        <h6>{((Object.keys(runningStatus).length) != 0 && (runningStatus[item._id])) ? (runningStatus[item._id].best_shift_efficency ?? "0") : ("0")}%</h6>
                                    </div>

                                </div>
                            </div>
                        ))) : (<></>)

                    } */}

                    <div style={{
                        width: "100%",
                        flexWrap: "wrap",
                        display: "inline-flex",
                        justifyContent: "space-around",
                        height: "100%",
                    }}>
                        {((Object.keys(runningStatus).length)) ?
                            graph.map((item, index) => {
                                return (
                                    <div key={index} className='machine_card' style={{ backgroundColor: machineTime(item._id, item.alarm_color) }}>
                                        {runningStatus[item._id] !== undefined ?
                                            (
                                                ((runningStatus[item._id].shift_status) ?
                                                    <div>
                                                        <div style={{ paddingHorizontal: "2%" }}>
                                                            {/* first row */}
                                                            <div style={{ paddingTop: 5, marginLeft: 5 }} className='inLine'>
                                                                {((Object.keys(runningStatus).length) !== 0 && (runningStatus[item._id])) ? (machineStatus(item._id)) : ("")}

                                                                <div style={{ marginLeft: 20 }}>
                                                                    <h4 className='TextMachName'>{item.sensor_name}</h4>
                                                                    <h5 className='TextDevName'>{item.device_name}</h5>
                                                                </div>
                                                            </div>

                                                            {/* 2nd row */}

                                                            {item.counter_flag === "Y" ?
                                                                <div style={{ justifyContent: "space-between" }} className='inLine'>
                                                                    {runningStatus.hasOwnProperty(item._id) ?
                                                                        <h3 className='machineTimeText'>{(runningStatus[item._id].on_time)}</h3>
                                                                        :
                                                                        <h3 className='machineTimeText'>--:--</h3>
                                                                    }

                                                                    <h4 style={{ width: "40%", flexWrap: 'wrap' }} className='machineTimeText1'>{runningStatus[item._id].sensor_count} {runningStatus[item._id].counterUnit}</h4>

                                                                    <h4 className='machineEff'>
                                                                        {((Object.keys(runningStatus).length) != 0 && (runningStatus[item._id])) ? (runningStatus[item._id].efficiency) : ("NA")}%
                                                                    </h4>

                                                                </div>
                                                                :
                                                                <div style={{ justifyContent: "space-between" }} className='inLine'>

                                                                    {runningStatus.hasOwnProperty(item._id) ?
                                                                        <h3 className='machineTimeText'>{(runningStatus[item._id].on_time)}</h3>
                                                                        :
                                                                        <h3 className='machineTimeText'>--:--</h3>
                                                                    }
                                                                    <h4 className='machineEff'>
                                                                        {((Object.keys(runningStatus).length) != 0 && (runningStatus[item._id])) ? (runningStatus[item._id].efficiency) : ("NA")}%
                                                                    </h4>
                                                                </div>
                                                            }

                                                            <div style={{ display: 'flex', width: '100%', height: '10px', borderRadius: '10px', marginBottom: "3px" }}>
                                                                {(Object.keys(colorWidthData).length && (colorWidthData[item._id])) ?
                                                                    colorWidthData[item._id].map(({ color, width }, index) => (
                                                                        <div key={index} style={{ width, backgroundColor: color, borderRadius: (borderRadius(index, item._id)) }} ></div> //(index == 0 ? '10px 0 0 10px' : (index == colorWidthData[item.machine_id].length - 1 ? '0px 10px 10px 0px' : '0px')) 

                                                                    )) :
                                                                    <div key={index} style={{ width: "100%", backgroundColor: "gray", borderRadius: "10px", height: "10px" }} ></div>}
                                                            </div>


                                                            {/* 3rd row */}

                                                            <div>
                                                                <div style={{ margin: "5px" }} className='inLine'>
                                                                    <h4 className='shiftTime'>Current Shift</h4>
                                                                    <h5 className="shiftTimedata" style={{ margin: "0px 10px", color: "#49454F" }}>{((Object.keys(runningStatus).length) != 0 && (runningStatus[item._id])) ? showShift(item._id) : ("NA")} </h5>
                                                                </div>
                                                            </div>

                                                            <div style={{ justifyContent: "space-between", marginBottom: 10 }} className='inLine'>
                                                                <div style={{ marginHorizontal: 10 }}>
                                                                    {((Object.keys(runningStatus).length) != 0 && (runningStatus[item._id]) && (runningStatus[item._id].previousData.machine_time_id !== 0)) ?
                                                                        <div>
                                                                            <h4 className='shiftTime'>Previous Shift</h4>
                                                                            <div className='inLine'>
                                                                                <h5 className="shiftTimedata" style={{ color: "#938F99" }}>{((Object.keys(runningStatus).length) != 0 && (runningStatus[item._id])) ? PrevshowShift(item._id) : ("NA")}  /  </h5>
                                                                                <h5 className="shiftTimedata" style={{ color: "#938F99" }}>{((Object.keys(runningStatus).length) != 0 && (runningStatus[item._id])) ? (runningStatus[item._id].previousData.eff) : ("NA")} %</h5>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <></>
                                                                    }
                                                                </div>

                                                                {/* <div style={{ backgroundColor: ButtonColor(item._id, item.alarm_color), cursor: "pointer" }} className="DetailButton" onClick={() => { NavigateToLogs(item) }}>
                                                                    <h4 className="buttonText">
                                                                        View details
                                                                    </h4>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div style={{ width: "300px" }}>
                                                        <div style={{ width: "100%", flexDirection: 'row', alignItems: "center" }}>
                                                            <h4 style={{ width: "90%", fontSize: 21, fontFamily: "poppins, san-sarif", color: "black", textAlign: "center" }}>{item.sensor_name}</h4>


                                                        </div>
                                                        <div style={{
                                                            width: "100%", flexDirection: "row", alignItems: "center", justifyContent: "space-between"
                                                        }}>
                                                            <h4 style={{ fontSize: 14, fontFamily: "poppins, san-sarif", color: '#374955' }}>{item.device_name}</h4>
                                                            <div style={{
                                                                flexDirection: "row", alignItems: "center",
                                                            }}>
                                                                <h4 style={{ fontSize: 14, fontFamily: "poppins, san-sarif", color: '#374955', paddingRight: 10 }}> Current Shift Not Exist</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )

                                            ) :
                                            (
                                                <div style={{ width: "300px" }}>
                                                    <div style={{ width: "100%", flexDirection: 'row', alignItems: "center" }}>
                                                        <h4 style={{ width: "90%", fontSize: 21, fontFamily: "poppins, san-sarif", color: "black", textAlign: "center" }}>{item.sensor_name}</h4>


                                                    </div>
                                                    <div style={{
                                                        width: "100%", flexDirection: "row", alignItems: "center", justifyContent: "space-between"
                                                    }}>
                                                        <h4 style={{ fontSize: 14, fontFamily: "poppins, san-sarif", color: '#374955' }}>{item.device_name}</h4>
                                                        <div style={{
                                                            flexDirection: "row", alignItems: "center",
                                                        }}>
                                                            <h4 style={{ fontSize: 14, fontFamily: "poppins, san-sarif", color: '#374955', paddingRight: 10 }}> Current Shift Not Exist</h4>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            })
                            :
                            <div style={{ width: "95%", margin: '50px 2.5%', justifyContent: "space-between", display: "inline-flex", flexDirection: "row" }}>
                                {[...Array(3)].map((_, index) => (
                                    <div style={{ width: "25%" }} key={index}>
                                        <Skeleton style={{ height: 150 }} />
                                    </div>
                                ))}
                            </div>
                        }


                    </div>

                </div>



            </div>
            <div>
                <p className='error'>{error}</p>
            </div>


        </div >
    )
}

export default DeviceMachines