// import React from 'react'
// import { useNavigate } from 'react-router-dom';
// import "../Styles/TvCode.css"
// import axios from "axios";
// import { setTvDetails, getTvDetails } from "../Utility/AsyncStorageData"
// import flovation from "../Assets/logo11.png"
// import Version from '../Utility/Version';
// import QRCode from "react-qr-code";
// var intervalId = 0;



// function TvCode() {


//   let navigate = useNavigate();
//   let d = getTvDetails()
//   // let value = "QEZ1703572266XM";
//   // var tvCode = "";

//   const [tvCode, setTvCode] = React.useState(generateRandomString());
//   const [error, setError] = React.useState(null);
//   const [load, setLoad] = React.useState(d ? d.length : 0);
//   const [expired, setExpired] = React.useState(false);

//   // console.log("**************login*****************", load)

//   function generateRandomString() {
//     const generateRandomChars = (length) => {
//       const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
//       let result = '';
//       for (let i = 0; i < length; i++) {
//         const randomIndex = Math.floor(Math.random() * characters.length);
//         result += characters.charAt(randomIndex);
//       }
//       return result;
//     };

//     const firstThreeChars = generateRandomChars(3);
//     const unixTime = Math.floor(Date.now() / 1000);
//     const lastTwoChars = generateRandomChars(2);

//     const randomString = `${firstThreeChars}${unixTime}${lastTwoChars}`;
//     // tvCode = randomString

//     return randomString;
//   }

//   // const randomString = generateRandomString();
//   // console.log(randomString);


//   const verifyTvCode = () => {
//     // console.log("login Api")
//     try {

//       // console.log("getTvDetails", d)


//       if (d == null && tvCode.length === 0) {
//         return
//       }


//       let url = process.env.REACT_APP_BASE_URL + "/tv/verifyTvCode";
//       let body = {
//         tv_code: d != null ? d.tv_code : tvCode,
//       }

//       // console.log("response: ", body, url);
//       axios.post(url, body).then(response => {

//         if (response.status === 200) {
//           if (response.data.statusCode === 0) {
//             // console.log("response: ", response.data.data);
//             setTvDetails(JSON.stringify(response.data.data));
//             navigate("/dashboard");

//           }

//           else {
//             console.log("response: ", response.data.msg);
//           }
//         }

//       }).catch(error => {
//         if (error.response.status === 403) {
//           navigate("/");
//         }
//       });

//     } catch (error) {
//       console.log("ERROR");

//     }

//   }



//   clearInterval(intervalId);
//   intervalId = setInterval(() => {
//     verifyTvCode();
//   }, 5000);


//   React.useEffect(() => {
//     // verifyTvCode()
//     const timer = setTimeout(() => {setExpired(true); clearInterval(intervalId)}, 20000);
//     return () => {
//       // 👇️ clear timeout when the component unmounts
//       clearInterval(intervalId);
//       clearTimeout(timer)
//     };
//   }, []);


//   return (
//     <div style={{ backgroundColor: "white" }}>
//       {load != 0 ?
//         <div className='load'> <img src="https://tmmapi.9930i.com/loader" alt="Computer man" style={{ height: "150px" }} />
//         </div> :

//         <div className='TvCode_block'>
//           <div className='Tv_Header'>
//             <h1 className='heading'>SuperManager</h1>
//             <div className='inputSection' style={{ backgroundColor: "#1e2a7a14" }} >
//               <label>TV Code</label>
//               <div style={{ height: "auto", margin: "0 auto", maxWidth: 150, width: "100%" }}>
//                 {expired ?
//                   (
//                     <button onClick={()=> {setExpired(false); }} style={{ padding: '10px 20px', cursor: 'pointer' }}>
//                       Click to Reload QR
//                     </button>
//                   )
//                   :
//                   (
//                     <QRCode
//                       size={256}
//                       style={{ height: "auto", maxWidth: "100%", width: "100%" }}
//                       value={tvCode}
//                       viewBox={`0 0 256 256`}
//                     />
//                   )}
//               </div>
//               <h3 className='qr_codeName'>{tvCode}</h3>
//             </div>

//             <p className='error'>{error}</p>
//           </div>

//           <div className='logo_Block'>
//             <img src={flovation} alt="flovation_Logo" />
//             <p className='versionText' >Version {Version[0].ver}</p>
//           </div>
//         </div>
//       }
//     </div>
//   )
// }

// export default TvCode





import React from 'react';
import { useNavigate } from 'react-router-dom';
import "../Styles/TvCode.css";
import axios from "axios";
import { setTvDetails, getTvDetails } from "../Utility/AsyncStorageData";
import flovation from "../Assets/logo11.png";
import Version from '../Utility/Version';
import QRCode from "react-qr-code";
import Qrimage from "../Assets/Qrimage.png"

function TvCode() {
  let navigate = useNavigate();
  let d = getTvDetails();

  const [tvCode, setTvCode] = React.useState(generateRandomString());
  const [error, setError] = React.useState(null);
  const [load, setLoad] = React.useState(d ? d.length : 0);
  const [expired, setExpired] = React.useState(false);
  const intervalRef = React.useRef(null); // Use ref to store interval ID

  function generateRandomString() {
    const generateRandomChars = (length) => {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let result = '';
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters.charAt(randomIndex);
      }
      return result;
    };

    const firstThreeChars = generateRandomChars(3);
    const unixTime = Math.floor(Date.now() / 1000);
    const lastTwoChars = generateRandomChars(2);

    return `${firstThreeChars}${unixTime}${lastTwoChars}`;
  }

  const verifyTvCode = () => {
    if (d == null && tvCode.length === 0) return;

    let url = process.env.REACT_APP_BASE_URL + "/tv/verifyTvCode";
    let body = { tv_code: d != null ? d.tv_code : tvCode };

    axios.post(url, body)
      .then(response => {
        if (response.status === 200 && response.data.statusCode === 0) {
          setTvDetails(JSON.stringify(response.data.data));
          navigate("/dashboard");
        } else {
          console.log("response:", response.data.msg);
        }
      })
      .catch(error => {
        if (error.response?.status === 403) {
          navigate("/");
        }
      });
  };

  const startVerification = () => {
    // Clear any existing interval
    clearInterval(intervalRef.current);

    // Start a new interval for API calls
    intervalRef.current = setInterval(verifyTvCode, 5000);
  };

  React.useEffect(() => {
    startVerification(); // Start verification on component mount

    const timer = setTimeout(() => {
      setExpired(true);
      clearInterval(intervalRef.current); // Stop verification when QR expires
    }, 120000); //120 seconds expiration

    return () => {
      clearInterval(intervalRef.current);
      clearTimeout(timer);
    };
  }, [tvCode]);

  const handleReload = () => {
    setTvCode(generateRandomString()); // Generate new QR code
    setExpired(false); // Reset expiration state
    startVerification(); // Resume verification API calls
  };

  return (
    <div style={{ backgroundColor: "white" }}>
      {load !== 0 ? (
        <div className='load'>
          <img src="https://tmmapi.9930i.com/loader" alt="Computer man" style={{ height: "150px" }} />
        </div>
      ) : (
        <div className='TvCode_block'>
          <div className='Tv_Header'>
            <h1 className='heading'>SuperManager</h1>
            <div className='inputSection' style={{ backgroundColor: "#1e2a7a14" }}>
              <label>TV Code</label>
              <div>
                {expired ? (
                  <div>
                    <h5 onClick={handleReload} style={{ zIndex: 2, opacity: 1, position:"relative" ,top: 130, margin:"0px 35%", fontSize: 18, fontWeight: 400, backgroundColor:"#000000c7", padding:10, borderRadius:10, color:'white'  }}>Click to Reload QR</h5>
                    <button onClick={handleReload} style={{
                      padding: '10px 20px', cursor: 'pointer', height: 200, width: 200, borderRadius: 10, border: "1px solid gray",
                      backgroundImage: `url(${Qrimage})`, backgroundRepeat: "no-repeat", backgroundSize: 'cover', opacity: 0.2
                    }}>
                    </button>
                  </div>
                ) : (
                  <div style={{ height: "auto", margin: "0 auto", width: "100%", width: 200 }}>
                    <QRCode
                      size={256}
                      style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                      value={tvCode}
                      viewBox={`0 0 256 256`}
                    />
                    <h3 className='qr_codeName'>{tvCode}</h3>
                  </div>
                )}
              </div>
            </div>
            <p className='error'>{error}</p>
          </div>

          <div className='logo_Block'>
            <img src={flovation} alt="flovation_Logo" />
            <p className='versionText'>Version {Version[0].ver}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default TvCode;
