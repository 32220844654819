import React from "react";
import "../Styles/WebAdmin.css"
import { HomeWork, TocRounded, ExitToAppRounded, MobileFriendlyRounded, DashboardRounded, Phonelink, Person, VerifiedUser, VpnKey } from "@material-ui/icons";
import { motion } from "framer-motion"
import Item from "./Item";
import { removeUserDetails } from "../Utility/AsyncStorageData";
import { useNavigate } from 'react-router-dom';
import { getUserDetails } from "../Utility/AsyncStorageData"
import flovation from "../Assets/logo11.png"
// import { NavLink } from 'react-router-dom';

import user from "../Assets/usercopy.png"

let open1 = 1
export function greetController() {
  // console.log("greet")
  open1 = 2;
}

function ControllerNavbar() {
  // console.log("************ControllerNavbar************ ")
  let navigate = useNavigate();
  // var userDetails = getUserDetails();

  const [userDetails, setUserDetails] = React.useState(true)
  const [open, setOpen] = React.useState(true)
  const [screenWidth, setScreenWidth] = React.useState(true)
  const [screenWidth1, setScreenWidth1] = React.useState(window.innerWidth);


  if (open1 == 2) {
    // console.log("open", open)
    setOpen(!open)
    open1 = 1
  }


  const handleToogle = () => {
    // console.log("press", open)
    setOpen(!open)
  }

  const handleLogout = () => {
    removeUserDetails()
    localStorage.clear();
    // console.log("logout")
    navigate("/WebLogin");
  }

  const sideContainerVarient = {
    true: {
      width: "15rem ",
    },
    false: {
      width: "auto",
      transition: {
        delay: 0.6,
      }
    }
  }

  const sidebarDiv = {
    true: {},
    false: {
      // display: "none",
      width: screenWidth ? '3rem' : '0',
      display: screenWidth ? 'block' : 'none',
      transition: {
        delay: 0.4,
      }
    }
  }

  const profilevariant = {
    true: {
      alignSelf: "center",
      width: "100px"
    },
    false: {
      alignSelf: "flex-start",
      marginTop: "2rem",
      width: "50px",
    }
  }

  const userVariant = {
    true: {
      opacity: 1
    },
    false: {
      opacity: 0,
      display: "none",
    }
  }
  React.useEffect(() => {

    var userDetails = getUserDetails();
    setUserDetails(userDetails)

    if (window.innerWidth <= 780) {
      setScreenWidth(false)
    }
    else if (window.innerWidth >= 780) {
      setOpen(true)
      setScreenWidth(true)
    }

    const updateScreenWidth = () => {
      setScreenWidth1(window.innerWidth);
      if (window.innerWidth <= 780) {
        setScreenWidth(false)
      }
      else if (window.innerWidth >= 780) {
        setOpen(true)
        setScreenWidth(true)
      }
    };

    window.addEventListener('resize', updateScreenWidth);

    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []);

  return (
    <div className='main'>
      <motion.div
        data-open={open}
        variants={sideContainerVarient}
        initial={`${open}`}
        animate={`${open}`}
        className='navmenu'>
        <motion.div
          initial={`${open}`}
          animate={`${open}`}
          variants={sidebarDiv}
          className="sidebar">



          <motion.div whileHover={{
            scale: 1.2,
            rotate: 180,
            backgroundColor: "rgba(255, 255, 255, 0.3)",
            backdropFilter: "blur(3.5px)",
            WebkitBackdropFilter: "blur(3.5px)",
            border: "1px solid rgba(255, 255, 255, 0.13)",
            transition: {
              delay: 0.2,
              duration: 0.2
            }
          }}
            style={{
              display: (screenWidth ? "block" : "none")
            }}
            onClick={handleToogle}
            className="menu_icon">
            <TocRounded />
          </motion.div>



          <motion.div
            layout
            initial={`${open}`} animate={`${open}`} variants={profilevariant}
            transition={{ duration: 0.4 }}
            className="user_profile"
          >
            <img src={flovation} alt="user_profile" />
          </motion.div>

          <motion.h4 className="user_name" style={{marginBottom:10}}
            variants={userVariant}
            initial={`${open}`}
            animate={`${open}`}>
            <h4>{userDetails.name}</h4>
          </motion.h4>

          <div className="groups">
            <div className="group">
              <Item icon={<DashboardRounded />} name="Dashboard" path="/controllerDashboard" />
              <Item icon={<HomeWork />} name="Companies" path="/controllerComp" />
              <Item icon={<MobileFriendlyRounded />} name="Device" path="/controllerDevice" />
              <Item icon={<Phonelink />} name="Machines" path="/controllerMachine" />
              <Item icon={<Person />} name="User" path="/controllerUser" />
              <Item icon={<VerifiedUser />} name="Login User" path="/controllerActiveUser" />
              <Item icon={<VpnKey />} name="Otp" path="/controllerOtps" />

            </div>
          </div>


          <div className="logout">
            <motion.div
              whileHover={{
                scale: 1.2,
                backgroundColor: "rgba(255, 255, 255, 0.3)",
                backdropFilter: "blur(3.5px)",
                WebkitBackdropFilter: "blur(3.5px)",
                border: "1px solid rgba(255, 255, 255, 0.13)",
                transition: {
                  delay: 0.2,
                  duration: 0.2
                }
              }} onClick={handleLogout}
              className="logout_icon">
              <ExitToAppRounded />
            </motion.div>
          </div>

          <hr className="line"></hr>

        </motion.div>
      </motion.div>
    </div>
  )
}

export default ControllerNavbar

