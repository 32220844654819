import React from 'react'
import Navbar from './Navbar'
import "../Styles/DeviceTable.css"
import "../Styles/UserCard.css"
import Modal from "./Modal";
import { getUserDetails, getDeviceDetails } from "../Utility/AsyncStorageData";
import axios from "axios";
import flovation from "../Assets/logo1.png"
import Report from '../Utility/Report'
import Header from "./Header";
import { useNavigate } from 'react-router-dom';

let s = {
    "alarm_alert": { "admin": "20", "floor_manager": "15", "machine_incharge": "5" }, "alarm_color": { "G": "100", "R": "40", "Y": "60" }, "load": { "free_load": "4", "on_load": "12", "zero_load": "3" }
}


function WebSensor() {

    let navigate = useNavigate();
    var userDetails = getUserDetails();
    var DeviceDetails = getDeviceDetails();
    const [error, setError] = React.useState('');
    const [sensorList, setSensorList] = React.useState([
        {
        }
    ])

    const [test, setTest] = React.useState(1);
    const [open, setOpen] = React.useState(false);
    const [add, setAdd] = React.useState(null);
    const [machineData, setMachineData] = React.useState(s)
    const [openExcel, setOpenExcel] = React.useState(false);
    const [reportMachineId, setReportMachineId] = React.useState(null);

    const [shifts, setShifts] = React.useState([]);

    const addShift = () => {
        setShifts([...shifts, { start_time: null, end_time: null }]);
    };

    const deleteShift = (index) => {
        const updatedShifts = [...shifts];
        updatedShifts.splice(index, 1);
        setShifts(updatedShifts);

        let tempSensor = { ...machineData };
        tempSensor.shift_time = updatedShifts
        setMachineData(tempSensor)
    };



    const handleFetchSensor = async () => {
        try {
            let url = process.env.REACT_APP_BASE_URL + "/sensor/getSensorList";
            let body = {
                comp_num: userDetails.comp_num,
                device_id: DeviceDetails.device_id
            }
            // console.log("**********getSensorList********** ", body)
            await axios.post(url, body, {
                headers: {
                    'authorization': `${userDetails.access_token}`
                },
            }).then(response => {
                // console.log("response: ", response.data);
                // 
                if (response.status === 200) {

                    if (response.data.statusCode === 0) {
                        setSensorList(response.data.result)
                        // console.log("getSensorList  ----------------", response.data.result)
                    }
                    else if (response.data.statusCode === 2) {

                        setError(response.data.msg)
                        setTimeout(() => errortimer(), 2000)
                    }

                    else {

                        setError(response.data.msg)
                        setTimeout(() => errortimer(), 2000)
                        // console.log(" getSensorList error: ", response.data.msg);
                    }

                    // setLoading (false);
                }


                else if (response.status == 403) {
                    navigate("/");
                }
            }).catch(error => {
                console.log("error: ", error);
                if (error.response.status == 403) {
                    navigate("/");
                }
                setError("ERROR")
                setTimeout(() => errortimer(), 2000)
                return
            })
        } catch (error) {
            console.log("error ", error)
            throw error;
        }

        // setLoading(false);
    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setMachineData(values => ({ ...values, [name]: value }))
    }

    const handleFieldChange = (fieldName, value, index) => {
        // if (fieldName == "sensor_type") {
        //     setSensorType(value)
        // }
        if (fieldName == "zero_load" || fieldName == "free_load" || fieldName == "on_load") {
            let temp = machineData.load ?? {};
            temp[fieldName] = value;
            value = temp;
            fieldName = "load";
        }

        if (fieldName == "R" || fieldName == "Y" || fieldName == "G") {
            let temp = machineData.alarm_color ?? {};
            temp[fieldName] = value;
            value = temp;
            fieldName = "alarm_color";
        }

        if (fieldName == "machine_incharge" || fieldName == "floor_manager" || fieldName == "admin") {
            let temp = machineData.alarm_alert ?? {};
            temp[fieldName] = value;
            value = temp;
            fieldName = "alarm_alert";
        }

        if (fieldName == "start_time" || fieldName == "end_time") {
            let temp = machineData.shift_time ?? [{ start_time: "00:00:00", end_time: "00:00:00" }];
            // console.log(index, temp.length)
            if ((index == temp.length) && index != 0) {
                machineData.shift_time.push({ start_time: "00:00:00", end_time: "00:00:00" })
            }
            temp[index][fieldName] = value;
            value = temp;
            fieldName = "shift_time";
        }

        setMachineData((prevData) => ({
            ...prevData,
            [fieldName]: value,
        }));
    };

    const closeAddSensorForm = async () => {
        try {

            let url = process.env.REACT_APP_BASE_URL + "/sensor/addSensor";
            let body = {
                device_id: DeviceDetails.device_id,
                sensor_data: machineData,
            }
            // console.log("**************addSensor body************** ", body)
            await axios.post(url, body, {
                headers: {
                    'authorization': `${userDetails.access_token}`
                },
            }).then(response => {
                // console.log("response: ", response.data);

                if (response.status === 200) {

                    if (response.data.statusCode === 0) {

                        handleFetchSensor()

                        setError(response.data.msg)
                        setTimeout(() => errortimer(), 2000)
                        setMachineData(s);
                        setOpen(false)
                        setShifts([]);
                        return
                    }

                    else {
                        console.log(" addSensor error: ", response.data.msg);
                        setError(response.data.msg)
                        setTimeout(() => errortimer(), 2000)
                        return
                    }
                }
            }).catch(error => {
                console.log("error: ", error);
                if (error.response.status == 403) {
                    navigate("/");
                }
                setError("ERROR")
                setTimeout(() => errortimer(), 2000)
                return
            })
        } catch (error) {
            console.log("error ", error)
            throw error;
        }
    };

    const editSensorMachine = (item) => {
        console.log("edit machine", item)
        setMachineData(item)
        setAdd(true)
        setShifts([...item.shift_time])
        setOpen(true)
    }



    const errortimer = () => {
        setError(undefined)
    }

    const handleClose = () => {
        setMachineData(s);
        setShifts([])
        setOpen(false);
    };

    const ShowsensorData = (sensorType) => {
        if (sensorType == "Modbus" || sensorType == "modbus") {
            return (
                <div>
                    <div>
                        <label>Enter Slave Id</label>
                        <input className='inputField'
                            type="text"
                            name="slave_id"
                            value={machineData.slave_id || ""}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <label>Enter Slave Adderss</label>
                        <input className='inputField'
                            type="text"
                            name="address"
                            value={machineData.address || ""}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            )
        }

        else if (sensorType == "Digital" || sensorType == "digital") {
            return (
                <div>
                    <label>Enter Digital Channel</label>
                    <input className='inputField'
                        type="text"
                        name="analog_channel"
                        value={machineData.analog_channel || ""}
                        onChange={handleChange}
                    />
                </div>
            )
        }

        else {
            return (
                <div>
                    <label>Enter Analog Channel</label>
                    <input className='inputField'
                        type="text"
                        name="analog_channel"
                        value={machineData.analog_channel || ""}
                        onChange={handleChange}
                    />
                </div>
            )
        }
    }

    const openExcelFlie = (item) => {
        setOpenExcel(true)
        setReportMachineId(item._id)
        // console.log("item", item)
        // setReportDeviceId(item.device_id)
    }

    const receiveDataFromChild = (data) => {
        // Do something with the data received from the child component
        // console.log('Data received from child:', data);
        setOpenExcel(false);
    };


    const navbarRender = (data) => {
        // console.log('Data received from child:', data);
        setTest(!test)
    };


    React.useEffect(() => {
        handleFetchSensor();
    }, []);

    return (
        <div className='main'>

            <div>
                <Navbar />
            </div>

            <div className='Admin_menu'>

                <Header show={1} sendNavbarToParent={navbarRender} />

                <h3 style={{ width: "100%", textAlign: "center", color: "rgb(5, 19, 120)" }}>Machines</h3>

                <div className='device_table'>
                    <table>
                        <thead>
                            <tr>
                                <th style={{ borderRadius: "10px 0 0 10px" }}>Sensor</th>
                                <th> Sensor Type</th>
                                <th>Slave id/Channel</th>
                                <th style={{ borderRadius: " 0 10px 10px 0" }}  >Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sensorList.map((item, index) => (
                                // <div key={index} className='user_card'>
                                //     <div className='row_1'>
                                //         <div className='user_name1'>
                                //             <h5>{index + 1}.</h5>
                                //             <h4 style={{ marginLeft: "10px" }}>{item.sensor_name}</h4>
                                //         </div>
                                //         <h5> {item.sensor_type} </h5>
                                //     </div>

                                //     <div className='row_1'>
                                //         {(item.sensor_type == "Modbus" ? <h5> Slave Id: {item.slave_id} </h5> : <h5> channel : {item.analog_channel}</h5>)}
                                //         <div style={{ display: "inline-flex", flexDirection: "row", justifyContent: "space-between", width: "50px", alignItems: "center" }}>
                                //             <i className="fa-solid fa-pen" onClick={() => editSensorMachine(item)}></i>
                                //             <i className="fa fa-file-excel-o" onClick={() => openExcelFlie(item)}></i>
                                //         </div>
                                //     </div>
                                // </div>
                                <tr className="device_tr" key={index}>
                                    <td style={{ borderRadius: "10px 0 0 10px" }}>{item.sensor_name}</td>
                                    <td>{item.sensor_type}</td>
                                    <td>{(item.sensor_type == "Modbus" ? item.slave_id : item.analog_channel)}</td>
                                    <td style={{ borderRadius: " 0 10px 10px 0" }}>
                                        <i className="fa-solid fa-pen" onClick={() => editSensorMachine(item)}></i>
                                        <i className="fa fa-file-excel-o" onClick={() => openExcelFlie(item)}></i>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>


                </div>

                <Modal isOpen={open}>

                    <div style={{ display: "inline-flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <h2 style={{ margin: "0", padding: "0", fontFamily: "'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif" }}> {add ? "Edit Device" : "Add Device"}</h2>
                        <i className="fa fa-close" onClick={handleClose}></i>
                    </div>
                    <div className='fields' style={{height:"400px", overflowY: "scroll"}}>
                        <div>
                            <label>Enter Machine Name</label>
                            <input className='inputField'
                                type="text"
                                name="sensor_name"
                                value={machineData.sensor_name || ""}
                                onChange={handleChange}
                            />
                        </div>

                        <div>
                            <label>Select Machine Type</label>
                            <select
                                className="custom-dropdown"
                                name="sensor_type"
                                value={machineData.sensor_type}
                                onChange={handleChange}
                            >
                                <option value="" hidden>Select...</option> {/* Placeholder option */}
                                <option value="Analog">Analog</option>
                                <option value="Digital">Digital</option>
                                <option value="Modbus">Modbus</option>
                            </select>
                        </div>

                        {ShowsensorData(machineData.sensor_type)}

                        <div>
                            <label>Shifts</label>
                            <div>
                                {shifts.map((shift, index) => (
                                    <div key={index} style={{ margin: "5px 0" }}>
                                        <input className='shift_time'
                                            type="time"
                                            value={shift.start_time}
                                            name="start_time"
                                            onChange={(e) => handleFieldChange("start_time", e.target.value, index)}
                                        />
                                        <span> to </span>
                                        <input className='shift_time'
                                            type="time"
                                            value={shift.end_time}
                                            name="end_time"
                                            onChange={(e) => handleFieldChange("end_time", e.target.value, index)}
                                        />
                                        <span
                                            style={{ marginLeft: "5%", width: "25%", borderRadius: "5px", textAlign: "center", border: "1px solid gray", padding: "4px", fontSize: "17px" }}
                                            onClick={() => deleteShift(index)}>

                                            <i className="fa fa-trash"></i>
                                        </span>
                                    </div>
                                ))}
                            </div>
                            <button style={{
                                alignItems: "center", width: "80%", backgroundColor: "#252B42", padding: "5px", marginTop: "5px", marginLeft: "10%",
                                borderRadius: "10px", border: "none", color: "white"
                            }} onClick={addShift}>Add Shift</button>
                        </div>


                        <div>
                            <label>Machine Load Values</label>
                            <div style={{ width: '100%', margin: '10px 0 0 0' }}>
                                <div className='loadsValue'>
                                    <span className='sub_label'>For zero load</span>
                                    <input
                                        className='loadsFiels'
                                        type="text"
                                        placeholder="4"
                                        value={machineData.load ? machineData.load.zero_load || '' : ''}
                                        onChange={(e) => handleFieldChange('zero_load', e.target.value)}
                                    />
                                </div>

                                <div className='loadsValue'>
                                    <span className='sub_label'>For free load</span>
                                    <input
                                        className='loadsFiels'
                                        type="text"
                                        placeholder="12"
                                        value={machineData.load ? machineData.load.free_load || '' : ''}
                                        onChange={(e) => handleFieldChange('free_load', e.target.value)}
                                    />
                                </div>

                                <div className='loadsValue'>
                                    <span className='sub_label'>For On load</span>
                                    <input
                                        className='loadsFiels'
                                        type="text"
                                        placeholder="18"
                                        value={machineData.load ? machineData.load.on_load || '' : ''}
                                        onChange={(e) => handleFieldChange('on_load', e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div>
                            <label>Efficiency Color</label>
                            <div style={{ width: '100%', margin: '10px 0 0 0' }}>
                                <div className='loadsValue'>
                                    <span className='sub_label'> Red </span>
                                    <input
                                        className='loadsFiels'
                                        type="text"
                                        placeholder="4"
                                        value={machineData.alarm_color ? (machineData.alarm_color.R) : ''}
                                        onChange={(e) => handleFieldChange('R', e.target.value)}
                                    />
                                </div>

                                <div className='loadsValue'>
                                    <span className='sub_label'>Orange</span>
                                    <input
                                        className='loadsFiels'
                                        type="text"
                                        placeholder="12"
                                        value={machineData.alarm_color ? (machineData.alarm_color.Y) : ''}
                                        onChange={(e) => handleFieldChange('Y', e.target.value)}
                                    />
                                </div>

                                <div className='loadsValue'>
                                    <span className='sub_label'>Green</span>
                                    <input
                                        className='loadsFiels'
                                        type="text"
                                        placeholder="18"
                                        value={machineData.alarm_color ? machineData.alarm_color.G : ''}
                                        onChange={(e) => handleFieldChange('G', e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div>
                            <label>Alarm Alert in Minutes</label>
                            <div style={{ width: '100%', margin: '10px 0 0 0' }}>
                                <div className='loadsValue'>
                                    <span className='sub_label'> Machine Incharge</span>
                                    <input
                                        className='loadsFiels'
                                        type="text"
                                        placeholder="5"
                                        value={machineData.alarm_alert ? (machineData.alarm_alert.machine_incharge) : ''}
                                        onChange={(e) => handleFieldChange('machine_incharge', e.target.value)}
                                    />
                                </div>

                                <div className='loadsValue'>
                                    <span className='sub_label'>Floor Manager</span>
                                    <input
                                        className='loadsFiels'
                                        type="text"
                                        placeholder="10"
                                        value={machineData.alarm_alert ? (machineData.alarm_alert.floor_manager) : ''}
                                        onChange={(e) => handleFieldChange('floor_manager', e.target.value)}
                                    />
                                </div>

                                <div className='loadsValue'>
                                    <span className='sub_label'>Admin</span>
                                    <input
                                        className='loadsFiels'
                                        type="text"
                                        placeholder="15"
                                        value={machineData.alarm_alert ? machineData.alarm_alert.admin : ''}
                                        onChange={(e) => handleFieldChange('admin', e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>


                        <button onClick={closeAddSensorForm}
                            style={{
                                backgroundColor: "#252B42 ", height: "30px", borderRadius: "10px", textDecoration: "none",
                                border: "0", marginTop: "30px", color: "white", fontSize: "15px", fontWeight: "500"
                            }}>Submit
                        </button>

                    </div>
                </Modal>

                <Modal isOpen={openExcel}>
                    <div>
                        <div className='report_download'>
                            <h3>Report Download</h3>
                            <i className="fa-solid fa-xmark" onClick={() => setOpenExcel(false)}></i>
                        </div>
                        <Report show={2} device_id={DeviceDetails.device_id} machine_id={reportMachineId} sendDataToParent={receiveDataFromChild} />
                    </div>
                </Modal>

                <div className='addBlock1'>
                    <p style={{
                        color: (error === "Successful" ? "green" : "red")
                    }} className='error1'>{error}</p>
                    <i class="fa fa-plus-square" onClick={() => { setOpen(true); setAdd(false); }}></i>
                </div>



            </div>
        </div >
    )
}

export default WebSensor
