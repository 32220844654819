// import React from 'react';
// import ReactDOM from 'react-dom';
// // import '../Styles/Toast.css';
// import "../Styles/Toast.css";

// export function showToast(message, duration = 3000, color) {
//   // Create a container element for the toast
//   const toastContainer = document.createElement('div');
//   document.body.appendChild(toastContainer); // Append the toast to the DOM

//   // Toast component definition
//   const Toast = (
//     <div className="toast" style={{backgroundColor: color}}>
//       <p>{message}</p>
//     </div>
//   );

//   // Render the toast to the container
//   ReactDOM.render(Toast, toastContainer);

//   // Remove the toast after the specified duration
//   setTimeout(() => {
//     ReactDOM.unmountComponentAtNode(toastContainer);
//     document.body.removeChild(toastContainer);
//   }, duration);
// }


import React from "react";
import ReactDOM from "react-dom/client";
import "../Styles/Toast.css";

export function showToast(message, duration = 3000, color) {
  const toastContainer = document.createElement("div");
  document.body.appendChild(toastContainer); 

  const Toast = (
    <div className="toast" style={{ backgroundColor: color }}>
      <p>{message}</p>
    </div>
  );

  const root = ReactDOM.createRoot(toastContainer);
  root.render(Toast);

  setTimeout(() => {
    root.unmount();
    document.body.removeChild(toastContainer);
  }, duration);
}
