import React from 'react';
// import Map from "mapmyindia-react";
// import markerIcon from "../Assets/Search.png";
// import '../Style/Navbar.css'


function MapLayer(props) {
  const { mapPointers } = props;
  console.log('*********** MapLayer props ***********', mapPointers);

  return (
    <div id="map" style={{margin: "2% 5% 0 5%", borderRadius: "15px", zIndex: "-1", }}>
      {/* <Map
      style={{height: "500px"}}
        key={JSON.stringify(mapPointers)}
        center={[22.68645667, 75.86017167]}
        zoom={12}

        markers={
          mapPointers.map(pointer => (
            {
              position: [pointer.lat, pointer.lon],
              title: pointer.tag_name,
              draggable: false,
              icon: markerIcon
            }
          ))
        }
      /> */}
    </div>
  );
}

export default MapLayer;

