import React from 'react'
import ControllerNavbar from '../../WebAppComponents/ControllerNavbar'
import "../../Styles/DeviceTable.css"
import "../../Styles/UserCard.css"
import "../../Styles/ControllerDashboard.css"
import axios from "axios";
import Header from "../../WebAppComponents/Header";
import { getUserDetails } from "../../Utility/AsyncStorageData";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import MapLayer from '../Map'


function ControllerDashboard() {


  var userDetails = getUserDetails();
  let navigate = useNavigate();

  console.log('\\\\\\\\\\\\\\\\\ControllerDashboard\\\\\\\\\\\\\\\\\\\\\\\\\\')
  const [error, setError] = React.useState('');
  const [cords, setCords] = React.useState([]);
  const [dashboardData, setDashboardData] = React.useState({});
  const [test, setTest] = React.useState(1);


  const companyWiseDeviceList = async () => {
    try {
      // console.log("*********** companyWiseDeviceList ***********")


      let url = process.env.REACT_APP_BASE_URL + "/device/companyWiseDeviceList";
      let body = {}
      // console.log("===============companyWiseDeviceList======================")

      await axios.post(url, body, {
        headers: {
          'authorization': `${userDetails.access_token}`
        },
      }).then(response => {

        if (response.status === 200) {

          if (response.data.statusCode === 0) {
            let i = 0
            const markers = response.data.data.map(obj => {
              if (obj.cords != undefined && obj.cords.length) {
                const [lat, lng] = obj.cords.split(',').map(coord => parseFloat(coord));
                return { id: i++, lat, lng };
              }

            });

            const filteredMarkers = markers.filter(marker => marker !== undefined);
            console.log("companyWiseDeviceList", filteredMarkers);
            setCords(filteredMarkers)
          }

          else {

            setError(response.data.msg)
            setTimeout(() => errortimer(), 2000)
            console.log("error in getCompanyWiseSensorList: ", response.data.msg);
          }
        }

        else if (response.status == 403) {
          navigate("/");
        }
      }).catch(error => {
        console.log("Error", error.response.status)
        if (error.response.status == 403) {
          navigate("/");
        }
        setError("ERROR")
        setTimeout(() => errortimer(), 2000)
      })
    } catch (error) {
      console.log("error ", error)
      throw error;
    }

  }


  const DashboardData = async () => {
    try {
      // console.log("*********** DashboardData ***********")


      let url = process.env.REACT_APP_BASE_URL + "/device/dashboard";
      let body = {
        from: moment().format("YYYY-MM-DD"),
        to: moment().format("YYYY-MM-DD")
      }
      // console.log("===============DashboardData======================", body)

      await axios.post(url, body, {
        headers: {
          'authorization': `${userDetails.access_token}`
        },
      }).then(response => {

        if (response.status === 200) {

          if (response.data.statusCode === 0) {
            // console.log("/device/dashboard", response.data.data)
            setDashboardData(response.data.data)
          }

          else {

            setError(response.data.msg)
            setTimeout(() => errortimer(), 2000)
            console.log("error in getCompanyWiseSensorList: ", response.data.msg);
          }
        }
      }).catch(error => {
        console.log("Error", error.response.status)
        if (error.response.status == 403) {
          navigate("/");
        }
        setError("ERROR")
        setTimeout(() => errortimer(), 2000)
      })
    } catch (error) {
      console.log("error ", error)
      throw error;
    }

  }

  const errortimer = () => {
    setError(undefined)
  }
  React.useEffect(() => {
    companyWiseDeviceList()
    DashboardData()
  }, []);

  const navbarRender = (data) => {
    // console.log('Data received from child:', data);
    setTest(!test)
  };





  return (
    <div className='main'>

      <div>
        <ControllerNavbar />
      </div>
              
      <div className='Admin_menu' style={{ overflowY: "scroll", marginTop: "10px", height: "100vh", scrollbarWidth: "none",
        backgroundColor: "aliceblue",
        borderRadius: "10px",
        marginRight: "10px",
       }}>
        <div>
          <Header show={3} sendNavbarToParent={navbarRender} />
        </div>
        <div className='block'>
          <div className='stats_Card' onClick={() => { navigate("/controllerComp"); }}>
            <div className='icon_block'>
              <i className="fa-solid fa-industry" style={{ backgroundColor: "orange" }}></i>
            </div>
            <div className='value'>
              <h4>Companies</h4>
              <h2>{dashboardData.company_count}</h2>
            </div>
          </div>

          <div className='stats_Card' onClick={() => { navigate("/controllerDevice"); }}>
            <div className='icon_block'>
              <i class="fa-solid fa-mobile-button" style={{ backgroundColor: "green" }}></i>
            </div>
            <div className='value'>
              <h4>Devices</h4>
              <h2>{dashboardData.device_count}</h2>
            </div>
          </div>


          <div className='stats_Card' onClick={() => { navigate("/controllerMachine"); }}>
            <div className='icon_block'>
              <i class="fa-solid fa-cash-register" style={{ backgroundColor: "red" }}></i>
            </div>
            <div className='value'>
              <h4>Machine</h4>
              <h2>{dashboardData.machine_count}</h2>
            </div>
          </div>

          <div className='stats_Card' onClick={() => { navigate("/controllerUser"); }}>
            <div className='icon_block'>
              <i class="fa-solid fa-user" style={{ backgroundColor: "blue" }}></i>
            </div>
            <div className='value'>
              <h4>Users</h4>
              <h2>{dashboardData.user_count}</h2>
            </div>
          </div>

          <div className='stats_Card' onClick={() => { navigate("/controllerActiveUser"); }}>
            <div className='icon_block'>
              <i class="fa-solid fa-user-check" style={{ backgroundColor: "pink" }}></i>
            </div>
            <div className='value'>
              <h4>Login User</h4>
              <h2>{dashboardData.login_count}</h2>
            </div>
          </div>

        </div>
        <div style={{ height: "500px" }}>
        <MapLayer mapPointers={cords} />
      </div>


      </div>
    </div >
  )
}

export default ControllerDashboard
